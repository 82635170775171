<template>
  <div>
    <v-container v-if="authorizedAccess">
      <v-layout row wrap justify-end class="pr-1 mb-1">
        <v-flex xs12 sm4 md4 lg4 class="text-left">
          <h1 class="title light-blue--text text--darken-2 text-uppercase">
            {{ $t("cancelled orders") }}
          </h1>
        </v-flex>
        <v-spacer></v-spacer>

        <v-flex xs12 sm4 md4 lg4 class="text-right">
          <v-btn
            v-show="showCancelOrder"
            outlined
            :color="$store.state.secondaryColor"
            small
            left
            @click="cancelledOrders()"
            class="mr-1 mt-1"
            >{{ $t("cancelled orders") }}
            <v-icon small right>mdi-clipboard-list</v-icon></v-btn
          >
          <v-btn
            outlined
            small
            right
            :color="$store.state.primaryColor"
            dark
            class="mr-1 mt-1"
            @click="allOrders()"
            v-if="!isChef"
            >{{ $t("view all orders") }} <v-icon right>mdi-eye</v-icon></v-btn
          >
        </v-flex>
      </v-layout>
      <v-layout row wrap justify-space-between class="mt-2">
        <v-flex xs12 sm4 md4 lg4 class="text-right pa-1">
          <v-text-field
            v-model="search"
            dense
            solo
            placeholder="Search orders"
          ></v-text-field> </v-flex
        ><v-spacer></v-spacer>
        <v-flex xs12 sm4 md2 lg2 class="text-right pa-1">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" color="white" @click="getOrders()"
                ><v-icon>mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("refresh table") }}</span>
          </v-tooltip>
        </v-flex>
        <v-flex xs12 sm4 md2 lg2 class="text-right pa-1">
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="orderDate"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="orderDate"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
                in
                solo
              ></v-text-field>
            </template>
            <v-date-picker v-model="orderDate" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false">
                {{ $t("cancel") }}
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.dialog.save(orderDate);
                  dateFilter();
                "
              >
                {{ $t("ok") }}
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-flex>
      </v-layout>
      <!-- cards -->
      <v-layout row wrap>
        <v-flex xs12 sm12 md12 lg12 class="text-right mb-4">
          <!--sort buttons-->

          <v-btn
            :color="
              button.selected
                ? $store.state.secondaryColor
                : $store.state.primaryColor
            "
            x-small
            outlined
            class="mx-1 mt-1"
            v-for="button in controlledAccessMenu"
            :key="button.title"
            @click="sortBy(button.action)"
          >
            <span class="caption text-lowercase">{{ button.title }}</span>
          </v-btn>
        </v-flex>
      </v-layout>

      <v-layout row wrap>
        <v-flex xs12 sm12 md12 lg12>
          <v-data-table
            :headers="controlledAccessTable"
            :items="filteredOrders"
            :items-per-page="20"
            :search="search"
            :sort-by.sync="sortByDate"
            :sort-desc.sync="sortDesc"
            :loading="loading"
            loading-text="Loading... Please wait"
          >
            <template v-slot:[`item.orderId`]="{ item }">
              <span class="font-weight-bold">{{ item.orderId }}</span>
            </template>
            <template v-slot:[`item.totalAmount`]="{ item }">
              {{ currencyFormat(item.totalAmount) }}
            </template>
            <template v-slot:[`item.dateCreated`]="{ item }">
              {{ formatDate(item.dateCreated) }}
            </template>

            <template v-slot:[`item.paymentStatus`]="{ item }">
              <span v-show="!isChef">
                <v-btn
                  small
                  text
                  rounded
                  :color="$store.state.primaryColor"
                  dark
                  v-if="item.status == false"
                  style="cursor: context-menu"
                  >{{ $t("refunded") }}
                  <v-icon right>mdi-cash-refund</v-icon></v-btn
                >
              </span>
            </template>

            <template v-slot:[`item.viewOrder`]="{ item }">
              <v-btn
                @click="showOrderedItems(item.orderId)"
                outlined
                small
                right
                :color="$store.state.secondaryColor"
                dark
                >{{ $t("view order") }} <v-icon right>mdi-eye</v-icon></v-btn
              >
            </template>

            <template v-slot:[`item.servingStatus`]="{ item }">
              <span v-show="!isChef">
                <v-btn color="success" outlined small left v-if="item.cooked">
                  <span class="caption font-weight-bold">{{
                    $t("ready")
                  }}</span>
                </v-btn>

                <v-btn outlined color="error" small left v-else>
                  <span class="caption font-weight-bold">
                    {{ $t("pending") }}</span
                  >
                </v-btn>
              </span>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>

      <!-- ############# -->
      <!-- ordered items dialog -->
      <v-dialog v-model="orderedItemsDialog" width="500">
        <v-card>
          <v-toolbar class="text-h5 gradients" dark>{{
            $t("ordered items")
          }}</v-toolbar>
          <v-card-text>
            <v-list three-line v-for="item in orderedItems" :key="item.id">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    <p>{{ item.item.name }}</p>
                    <p class="font-weight-light">
                      {{ currencyFormat(item.price) }}
                    </p>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn color="red" text @click="orderedItemsDialog = false">
              Close
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <div class="fill-height" v-else>
      <UnauthorizedAccess :user="user" />
    </div>
  </div>
</template>

<script>
import jwtdecode from "jwt-decode";
import { digits } from "@/plugins/functions";
import { format } from "date-fns";
import db from "@/plugins/fb";
import UnauthorizedAccess from "@/components/mijini/UnauthorizedAccess";

export default {
  components: {
    UnauthorizedAccess,
  },
  data: () => ({
    orderDate: format(new Date(), "yyyy-MM-dd"),
    userAccess: "",
    user: "",
    authorizedAccess: false,
    cart: [],
    modal: false,
    orderedItemsDialog: false,
    foodReadyDialog: false,
    sortDesc: true,
    sortByDate: "dateCreated",
    selectedShop: "",
    waiterIsCashier: false,
    items: [
      { title: "Cash", icon: "mdi-cash-fast" },
      { title: "Mpesa", icon: "mdi-cellphone" },
      { title: "Credit Card", icon: "mdi-credit-card" },
    ],
    menu: [
      {
        title: "All",
        action: "all",
        selected: true,
        level: [1, 2, 3, 4, 5],
      },
      {
        title: "Pending",
        action: "pending",
        selected: false,
        level: [1, 2, 3, 4, 5],
      },
      {
        title: "Ready",
        action: "ready",
        selected: false,
        level: [1, 2, 3, 4, 5],
      },
      {
        title: "Paid",
        action: "paid",
        selected: false,
        level: [1, 2, 3, 5],
      },
      {
        title: "Unpaid",
        action: "unpaid",
        selected: false,
        level: [1, 2, 3, 5],
      },
    ],
    loading: true,
    foodReadyLoading: false,
    snackErrorOrderPaid: false,
    isChef: false,
    markAsReady: false,
    showCancelOrder: false,
    search: "",
    busName: "",
    change: "",
    grandTotal: "",
    receipts: [],
    products: [],
    orderedItems: [],
    receipt: false,
    amountPayed: "",
    filteredOrders: [],
    orderId: 0,
    table: "",
  }),
  created() {
    this.checkAccessLevel();
  },
  computed: {
    selected() {
      return this.$store.getters.getCurrentBusiness;
    },

    ownersBusinessIds() {
      return this.$store.getters.getBusinessId;
    },
    ownersBusiness() {
      return this.$store.getters.getBusinesses;
    },
    controlledAccessMenu() {
      return this.menu.filter((val) => {
        if (val.level.includes(this.userAccess)) {
          return val;
        }
      });
    },
    controlledAccessTable() {
      return this.headers.filter((val) => {
        if (val.level.includes(this.userAccess)) {
          return val;
        }
      });
    },
    headers() {
      return [
        {
          text: this.$t("order number"),
          value: "orderId",
          class: "blue-grey darken-3 white--text font-weight-regular",
          level: [1, 2, 3, 4, 5],
        },
        {
          text: this.$t("total amount"),
          value: "totalAmount",
          class: "blue-grey darken-3 white--text font-weight-regular",
          level: [1, 2, 3, 5],
        },
        {
          text: this.$t("table"),
          value: "table",
          class: "blue-grey darken-3 white--text font-weight-regular",

          level: [1, 2, 3, 4, 5],
        },
        {
          text: this.$t("transaction date"),
          value: "dateCreated",
          class: "blue-grey darken-3 white--text font-weight-regular",
          level: [1, 2, 3, 4, 5],
        },
        {
          text: this.$t("payment status"),
          value: "paymentStatus",
          class: "blue-grey darken-3 white--text font-weight-regular",
          level: [1, 2, 3, 5],
        },

        {
          text: this.$t("view order"),
          value: "viewOrder",
          class: "blue-grey darken-3 white--text font-weight-regular",
          level: [1, 2, 3, 4, 5],
        },
        {
          text: this.$t("serving status"),
          value: "servingStatus",
          class: "blue-grey darken-3 white--text font-weight-regular",
          level: [1, 2, 3, 4],
        },
      ];
    },
  },

  mounted() {
    this.getCurrentUser();
    this.getOrders();
    this.getSettings();
  },

  methods: {
    digits,
    checkAccessLevel() {
      //Fetching current user details
      var data = jwtdecode(
        this.$store.state.token,
        this.$store.state.accessKey
      );
      this.userAccess = data.accessLevel.isSuperAdmin
        ? 0
        : data.accessLevel.isOwner
        ? 1
        : data.accessLevel.isManager
        ? 2
        : data.accessLevel.isWaiter
        ? 3
        : data.accessLevel.isChef
        ? 4
        : data.accessLevel.isCashier
        ? 5
        : 6;

      if (data.accessLevel.isOwner || data.accessLevel.isManager)
        this.authorizedAccess = true;
      else this.authorizedAccess = false;
      //transfer user details to global variable
      this.user = data;
    },
    getOrders() {
      this.orders = [];
      this.loading = true;
      //if logged in person is chef, only fetch uncooked orders
      if (this.selected) {
        db.collection("orders")
          .where("businessId", "==", this.selected)
          .where("status", "==", false)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.orders.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            if (
              this.$store.state.todaysDate &&
              this.$store.state.todaysDate.length != 0
            ) {
              let filtered = this.orders.filter(
                (item) =>
                  format(item.dateCreated.toDate(), "yyyy-MM-dd") ==
                  this.$store.state.todaysDate
              );
              this.filteredOrders = filtered;
            } else {
              this.filteredOrders = this.orders;
            }

            //keep products in vuex store

            this.$store.commit("SET_ORDERS", this.orders);
            this.$store.commit("SET_TODAY_DATE", "");
            this.loading = false;
          });
      }
    },

    //get user: hide payment button if not cashier
    getCurrentUser() {
      //Fetching current user details
      var data = jwtdecode(
        this.$store.state.token,
        this.$store.state.accessKey
      );

      if (data.accessLevel.isManager) {
        this.showCancelOrder = true;
      } else if (data.accessLevel.isChef) {
        this.isChef = true;
        this.markAsReady = true;
      }
    },
    getSettings() {
      db.collection("configurations")
        .doc(this.$store.state.employerId)
        .get()
        .then((snaps) => {
          if (snaps.exists) {
            this.waiterIsCashier = snaps.data().waiterIsCashier;
          } else {
            //set false as minumum default
            this.waiterIsCashier = false;
          }
        })
        .catch(() => {
          this.snackErrorConfig = true;
        });
      // ######
    },
    dateFilter() {
      let filtered = this.orders.filter(
        (item) =>
          format(item.dateCreated.toDate(), "yyyy-MM-dd") == this.orderDate
      );
      this.filteredOrders = filtered;
    },

    viewReceipt() {
      this.receipt = true;
    },
    //attach the selected currency to money
    currencyFormat(val) {
      return this.$store.state.currency.symbol + " " + Number(val).toFixed(2);
    },

    sortBy(prop) {
      var new_array;
      if (prop == "all") {
        this.menu[0].selected = true;
        this.menu[1].selected = false;
        this.menu[2].selected = false;
        this.menu[3].selected = false;
        this.menu[4].selected = false;
        //get all orders from vuex store
        this.orders = this.$store.state.orders;

        if (
          this.$store.state.todaysDate &&
          this.$store.state.todaysDate.length != 0
        ) {
          let filtered = this.orders.filter(
            (item) =>
              format(item.dateCreated.toDate(), "yyyy-MM-dd") ==
              this.$store.state.todaysDate
          );
          this.filteredOrders = filtered;
        } else {
          this.filteredOrders = this.orders;
        }
      } else if (prop == "pending") {
        this.menu[0].selected = false;
        this.menu[1].selected = true;
        this.menu[2].selected = false;
        this.menu[3].selected = false;
        this.menu[4].selected = false;
        this.orders = this.$store.state.orders;

        new_array = this.orders.filter((element) => element.cooked == false);

        this.orders = new_array;

        if (
          this.$store.state.todaysDate &&
          this.$store.state.todaysDate.length != 0
        ) {
          let filtered = this.orders.filter(
            (item) =>
              format(item.dateCreated.toDate(), "yyyy-MM-dd") ==
              this.$store.state.todaysDate
          );
          this.filteredOrders = filtered;
        } else {
          this.filteredOrders = this.orders;
        }
      } else if (prop == "ready") {
        this.menu[0].selected = false;
        this.menu[1].selected = false;
        this.menu[2].selected = true;
        this.menu[3].selected = false;
        this.menu[4].selected = false;
        this.orders = this.$store.state.orders;

        new_array = this.orders.filter((element) => element.cooked == true);

        this.orders = new_array;

        if (
          this.$store.state.todaysDate &&
          this.$store.state.todaysDate.length != 0
        ) {
          let filtered = this.orders.filter(
            (item) =>
              format(item.dateCreated.toDate(), "yyyy-MM-dd") ==
              this.$store.state.todaysDate
          );
          this.filteredOrders = filtered;
        } else {
          this.filteredOrders = this.orders;
        }
      } else if (prop == "paid") {
        this.menu[0].selected = false;
        this.menu[1].selected = false;
        this.menu[2].selected = false;
        this.menu[3].selected = true;
        this.menu[4].selected = false;
        this.orders = this.$store.state.orders;

        new_array = this.orders.filter((element) => element.paid == true);

        this.orders = new_array;

        if (
          this.$store.state.todaysDate &&
          this.$store.state.todaysDate.length != 0
        ) {
          let filtered = this.orders.filter(
            (item) =>
              format(item.dateCreated.toDate(), "yyyy-MM-dd") ==
              this.$store.state.todaysDate
          );
          this.filteredOrders = filtered;
        } else {
          this.filteredOrders = this.orders;
        }
      } else {
        this.menu[0].selected = false;
        this.menu[1].selected = false;
        this.menu[2].selected = false;
        this.menu[3].selected = false;
        this.menu[4].selected = true;
        this.orders = this.$store.state.orders;

        new_array = this.orders.filter((element) => element.paid == false);

        this.orders = new_array;

        if (
          this.$store.state.todaysDate &&
          this.$store.state.todaysDate.length != 0
        ) {
          let filtered = this.orders.filter(
            (item) =>
              format(item.dateCreated.toDate(), "yyyy-MM-dd") ==
              this.$store.state.todaysDate
          );
          this.filteredOrders = filtered;
        } else {
          this.filteredOrders = this.orders;
        }
      }
    },

    showOrderedItems(orderId) {
      //get selected order
      let ordersOfDay = this.orders.filter((item) => item.orderId == orderId);

      this.orderedItems = ordersOfDay[0].foodItems;

      this.orderedItemsDialog = true;
    },
    showOrder(orderId) {
      this.$router.push({ name: "ViewOrderDetails", params: { id: orderId } });
    },
    allOrders() {
      this.$router.push({ name: "allOrders" });
    },
    cancelledOrders() {
      this.$router.push({ name: "cancelledOrders" });
    },
    formatDate(val) {
      var date = val.toDate();
      return format(date, "yyyy-MM-dd HH:mm:ss:bb");
    },
  },
};
</script>

<style></style>
