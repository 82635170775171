var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.authorizedAccess)?_c('v-container',[_c('v-layout',{staticClass:"pr-1 mb-1",attrs:{"row":"","wrap":"","justify-end":""}},[_c('v-flex',{staticClass:"text-left",attrs:{"xs12":"","sm4":"","md4":"","lg4":""}},[_c('h1',{staticClass:"title light-blue--text text--darken-2 text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t("cancelled orders"))+" ")])]),_c('v-spacer'),_c('v-flex',{staticClass:"text-right",attrs:{"xs12":"","sm4":"","md4":"","lg4":""}},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.showCancelOrder),expression:"showCancelOrder"}],staticClass:"mr-1 mt-1",attrs:{"outlined":"","color":_vm.$store.state.secondaryColor,"small":"","left":""},on:{"click":function($event){return _vm.cancelledOrders()}}},[_vm._v(_vm._s(_vm.$t("cancelled orders"))+" "),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v("mdi-clipboard-list")])],1),(!_vm.isChef)?_c('v-btn',{staticClass:"mr-1 mt-1",attrs:{"outlined":"","small":"","right":"","color":_vm.$store.state.primaryColor,"dark":""},on:{"click":function($event){return _vm.allOrders()}}},[_vm._v(_vm._s(_vm.$t("view all orders"))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-eye")])],1):_vm._e()],1)],1),_c('v-layout',{staticClass:"mt-2",attrs:{"row":"","wrap":"","justify-space-between":""}},[_c('v-flex',{staticClass:"text-right pa-1",attrs:{"xs12":"","sm4":"","md4":"","lg4":""}},[_c('v-text-field',{attrs:{"dense":"","solo":"","placeholder":"Search orders"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),_c('v-flex',{staticClass:"text-right pa-1",attrs:{"xs12":"","sm4":"","md2":"","lg2":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"white"},on:{"click":function($event){return _vm.getOrders()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}],null,false,3400214305)},[_c('span',[_vm._v(_vm._s(_vm.$t("refresh table")))])])],1),_c('v-flex',{staticClass:"text-right pa-1",attrs:{"xs12":"","sm4":"","md2":"","lg2":""}},[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.orderDate,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.orderDate=$event},"update:return-value":function($event){_vm.orderDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"append-icon":"mdi-calendar","readonly":"","dense":"","in":"","solo":""},model:{value:(_vm.orderDate),callback:function ($$v) {_vm.orderDate=$$v},expression:"orderDate"}},'v-text-field',attrs,false),on))]}}],null,false,1845915133),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-date-picker',{attrs:{"scrollable":""},model:{value:(_vm.orderDate),callback:function ($$v) {_vm.orderDate=$$v},expression:"orderDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal = false}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.$refs.dialog.save(_vm.orderDate);
                _vm.dateFilter();}}},[_vm._v(" "+_vm._s(_vm.$t("ok"))+" ")])],1)],1)],1)],1),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{staticClass:"text-right mb-4",attrs:{"xs12":"","sm12":"","md12":"","lg12":""}},_vm._l((_vm.controlledAccessMenu),function(button){return _c('v-btn',{key:button.title,staticClass:"mx-1 mt-1",attrs:{"color":button.selected
              ? _vm.$store.state.secondaryColor
              : _vm.$store.state.primaryColor,"x-small":"","outlined":""},on:{"click":function($event){return _vm.sortBy(button.action)}}},[_c('span',{staticClass:"caption text-lowercase"},[_vm._v(_vm._s(button.title))])])}),1)],1),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":""}},[_c('v-data-table',{attrs:{"headers":_vm.controlledAccessTable,"items":_vm.filteredOrders,"items-per-page":20,"search":_vm.search,"sort-by":_vm.sortByDate,"sort-desc":_vm.sortDesc,"loading":_vm.loading,"loading-text":"Loading... Please wait"},on:{"update:sortBy":function($event){_vm.sortByDate=$event},"update:sort-by":function($event){_vm.sortByDate=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.orderId",fn:function(ref){
              var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.orderId))])]}},{key:"item.totalAmount",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.currencyFormat(item.totalAmount))+" ")]}},{key:"item.dateCreated",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.dateCreated))+" ")]}},{key:"item.paymentStatus",fn:function(ref){
              var item = ref.item;
return [_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isChef),expression:"!isChef"}]},[(item.status == false)?_c('v-btn',{staticStyle:{"cursor":"context-menu"},attrs:{"small":"","text":"","rounded":"","color":_vm.$store.state.primaryColor,"dark":""}},[_vm._v(_vm._s(_vm.$t("refunded"))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-cash-refund")])],1):_vm._e()],1)]}},{key:"item.viewOrder",fn:function(ref){
              var item = ref.item;
return [_c('v-btn',{attrs:{"outlined":"","small":"","right":"","color":_vm.$store.state.secondaryColor,"dark":""},on:{"click":function($event){return _vm.showOrderedItems(item.orderId)}}},[_vm._v(_vm._s(_vm.$t("view order"))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-eye")])],1)]}},{key:"item.servingStatus",fn:function(ref){
              var item = ref.item;
return [_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isChef),expression:"!isChef"}]},[(item.cooked)?_c('v-btn',{attrs:{"color":"success","outlined":"","small":"","left":""}},[_c('span',{staticClass:"caption font-weight-bold"},[_vm._v(_vm._s(_vm.$t("ready")))])]):_c('v-btn',{attrs:{"outlined":"","color":"error","small":"","left":""}},[_c('span',{staticClass:"caption font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("pending")))])])],1)]}}],null,true)})],1)],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.orderedItemsDialog),callback:function ($$v) {_vm.orderedItemsDialog=$$v},expression:"orderedItemsDialog"}},[_c('v-card',[_c('v-toolbar',{staticClass:"text-h5 gradients",attrs:{"dark":""}},[_vm._v(_vm._s(_vm.$t("ordered items")))]),_c('v-card-text',_vm._l((_vm.orderedItems),function(item){return _c('v-list',{key:item.id,attrs:{"three-line":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-bold"},[_c('p',[_vm._v(_vm._s(item.item.name))]),_c('p',{staticClass:"font-weight-light"},[_vm._v(" "+_vm._s(_vm.currencyFormat(item.price))+" ")])])],1)],1)],1)}),1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){_vm.orderedItemsDialog = false}}},[_vm._v(" Close ")]),_c('v-spacer')],1)],1)],1)],1):_c('div',{staticClass:"fill-height"},[_c('UnauthorizedAccess',{attrs:{"user":_vm.user}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }